<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import VueCookies from "vue-cookies";
// VueCookies.config("", "", "", true);
import VueCookies from "vue-cookies";

export default {
  name: 'App',
  components: {
  },
  async created() {
    this.removeUnwantedCookies()
    // await this.$store.dispatch("fetchPermissions")
  },
  methods: {
  removeUnwantedCookies() {
    const keysToKeep = ['isClient', 'next-url', 'refresh_token', 'token','saas-token'];
    const allCookies = VueCookies.keys()
    allCookies.forEach((res)=>{
        if(!keysToKeep.includes(res)) {
            VueCookies.remove(res)
        }
    })
  }
  }
}
</script>

<style>
#app {
  height: 100vh;
}
</style>
